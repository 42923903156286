import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type {
  VideoSession,
  StreamingSession,
  CaptureData,
  TrackingData,
  TrainingSession,
  Connect,
  HomeContents,
  UserPreferences,
  User,
  Popularity,
  UserAttributes,
  ValidateTransaction,
  PutConnectIdAction,
  PosesDataType,
  UserPose,
  CreateSubscriptionOfferResponseBody,
  CreateSubscriptionOfferRequestBody,
  PostTrainingSessionNotififyResponseBody,
  PatchTrainingSessionRequestBody,
  PatchTrainingSessionResponseBody,
  GetConnectResponseBody,
  BusinessUser,
  PostBuisnessDeleteResponseBody,
  Voucher,
} from '@youga/youga-interfaces';

const SentryMock = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setContext: (type: string, data: any) => {
    // eslint-disable-next-line no-console
    console.log(`Sentry mock setContext: ${type}, ${JSON.stringify(data)}`);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  captureException: (e: any) => {
    // eslint-disable-next-line no-console
    console.log(`Sentry mock captureException: ${JSON.stringify(e)}`);
  },
};

interface JoinMailingList {
  email: string;
  list: string;
}

interface CourseSuggestionResponse {
  suggestions: string[];
}

const API = (
  baseUrl: string,
  Sentry: { setContext: Function; captureException: Function } = SentryMock,
) => ({
  joinMailingList: async (
    email: string,
    list: string,
  ): Promise<JoinMailingList> => {
    try {
      const response = await axios.post<JoinMailingList>(
        `${baseUrl}/mailing-list/join`,
        {
          email,
          list,
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/mailing-list/joing`,
      });
      throw e;
    }
  },
  getPosesData: async (): Promise<PosesDataType[]> => {
    try {
      const response = await axios.get<PosesDataType[]>(
        `${baseUrl}/home/poses`,
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },

  // buisness scope
  getBuisnessUsers: async (): Promise<BusinessUser[]> => {
    try {
      const response = await axios.get<BusinessUser[]>(
        `${baseUrl}/buisness-user`,
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  deleteBuisnessUser: async (
    idToken: string,
    buisnessUserId?: string,
  ): Promise<PostBuisnessDeleteResponseBody> => {
    try {
      const response = await axios.put<PostBuisnessDeleteResponseBody>(
        `${baseUrl}/business/del`,
        {
          companyId: buisnessUserId,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  selfDelete: async (
    idToken: string,
  ): Promise<PostBuisnessDeleteResponseBody> => {
    try {
      const response = await axios.put<PostBuisnessDeleteResponseBody>(
        `${baseUrl}/business/self-delete`,
        { idToken },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  getAnalyticsData: async (idToken: string, body: any): Promise<any> => {
    try {
      const response = await axios.put(
        `${baseUrl}/business/business-analytics`,
        body,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  createBuisnessUser: async (
    idToken: string,
    buisnessUserData: Omit<BusinessUser, 'id' | 'createdAt' | 'updatedAt'>,
  ): Promise<BusinessUser[]> => {
    try {
      const response = await axios.put<BusinessUser[]>(
        `${baseUrl}/business/business-user`,
        buisnessUserData,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/buisness-user`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  patchBuisnessUser: async (
    idToken: string,
    sessionId: string,
    payload: PatchTrainingSessionRequestBody,
  ): Promise<PatchTrainingSessionResponseBody> => {
    try {
      const response = await axios.patch<PatchTrainingSessionResponseBody>(
        `${baseUrl}/training-session/${sessionId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'patch',
        url: `${baseUrl}/training-session/${sessionId}`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },

  setCustomerAs: async (
    idToken: string,
    userId: string,
    state: 1 | 0,
  ): Promise<User> => {
    try {
      const response = await axios.post<User>(
        `${baseUrl}/buisnessUser`,
        {
          userId,
          state,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  setBuisnessCustomersList: async (
    idToken: string,
    emailList: string[],
  ): Promise<string[]> => {
    try {
      const response = await axios.post<string[]>(
        `${baseUrl}/setCustomers`,
        {
          emailList,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },

  getBuisnessCustomers: async (idToken: string): Promise<BusinessUser[]> => {
    try {
      const response = await axios.get<BusinessUser[]>(
        `${baseUrl}/business-get/business-clients`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },

  uploadBatchUsers: async (
    idToken: string,
    obj: any,
  ): Promise<BusinessUser[]> => {
    try {
      const response = await axios.put<BusinessUser[]>(
        `${baseUrl}/business/upload-users`,
        obj,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },

  createVoucher: async (idToken: string, obj: any): Promise<Voucher[]> => {
    try {
      const response = await axios.put<Voucher[]>(
        `${baseUrl}/business/business-code`,
        obj,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },
  verifyUser: async (idToken: string, obj: any): Promise<Voucher[]> => {
    try {
      const response = await axios.put<Voucher[]>(
        `${baseUrl}/business/confirm-user`,
        obj,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },
  // deleteUser: async (idToken: string, obj: any): Promise<Voucher[]> => {
  //   try {
  //     const response = await axios.put<Voucher[]>(
  //       `${baseUrl}/business/delete-user`,
  //       obj,
  //       {
  //         headers: { Authorization: `Bearer ${idToken}` },
  //       },
  //     );
  //     return response.data;
  //   } catch (e) {
  //     Sentry.setContext('request', {
  //       method: 'get',
  //       url: `${baseUrl}/home/poses`,
  //     });
  //     Sentry.captureException(e);
  //     throw e;
  //   }
  // },
  getVoucherById: async (idToken: string): Promise<Voucher[]> => {
    try {
      const response = await axios.get<Voucher[]>(
        `${baseUrl}/business-get/business-code`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },

  updateUser: async (
    idToken: string,
    editedUserId: string,
    newBusinessClientId: string,
    expirationDate: string,
  ): Promise<BusinessUser[]> => {
    try {
      const response = await axios.put<BusinessUser[]>(
        `${baseUrl}/business/set-new-user-status`,
        { editedUserId, newBusinessClientId, expirationDate },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/buisness-user`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  applyVoucher: async (
    idToken: string,
    code: string,
  ): Promise<BusinessUser[]> => {
    try {
      const response = await axios.put<BusinessUser[]>(
        `${baseUrl}/business/apply-voucher`,
        { code },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/buisness-user`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getB2BUsers: async (idToken: string): Promise<User[]> => {
    try {
      const response = await axios.get<User[]>(
        `${baseUrl}/business-get/business-user`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },

  deleteUser: async (idToken: string, body: any): Promise<User[]> => {
    try {
      const response = await axios.put(
        `${baseUrl}/business/delete-user`,
        body,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home/poses`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },

  getStreamingSession: async (streamId: string): Promise<StreamingSession> => {
    try {
      const response = await axios.get<StreamingSession>(
        `${baseUrl}/session?code=${streamId}`,
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/session?code=${streamId}`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  createStreamingSession: async (
    userId?: string,
  ): Promise<StreamingSession> => {
    try {
      const response = await axios.post<StreamingSession>(
        `${baseUrl}/session`,
        {
          userId,
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/session`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },
  postTrainingSessionNotify: async (
    idToken: string,
    trainingSessionId: string,
    origin: 'mobile' | 'desktop',
    connectId?: string,
  ): Promise<PostTrainingSessionNotififyResponseBody> => {
    try {
      const response =
        await axios.post<PostTrainingSessionNotififyResponseBody>(
          `${baseUrl}/training-session/${trainingSessionId}/notify`,
          { origin, connectId },
          {
            headers: { Authorization: `Bearer ${idToken}` },
          },
        );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/training-session/${trainingSessionId}/notify`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  putTrainingSession: async (
    idToken: string,
    sessionId: string,
    trainingSession: TrainingSession,
  ): Promise<TrainingSession> => {
    try {
      const response = await axios.put<TrainingSession>(
        `${baseUrl}/training-session?sessionId=${sessionId}`,
        trainingSession,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'put',
        url: `${baseUrl}/training-session?sessionId=${sessionId}`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  patchTrainingSession: async (
    idToken: string,
    sessionId: string,
    payload: PatchTrainingSessionRequestBody,
  ): Promise<PatchTrainingSessionResponseBody> => {
    try {
      const response = await axios.patch<PatchTrainingSessionResponseBody>(
        `${baseUrl}/training-session/${sessionId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'patch',
        url: `${baseUrl}/training-session/${sessionId}`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getCuratedVideos: async (): Promise<VideoSession[]> => {
    try {
      const response = await axios.get<VideoSession[]>(`${baseUrl}/video`);
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/video`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getTrainingSession: async (
    idToken: string,
    sessionId: string,
  ): Promise<TrainingSession> => {
    try {
      const response = await axios.get<TrainingSession>(
        `${baseUrl}/training-session?sessionId=${sessionId}`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/training-session?sessionId=${sessionId}`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getTrainingSessionAnimation: async (
    idToken: string,
    sessionId: string,
  ): Promise<{
    animatedImage: string;
    animatedImageSize: { width: number; height: number };
  }> => {
    try {
      const response = await axios.get<{
        animatedImage: string;
        animatedImageSize: { width: number; height: number };
      }>(`${baseUrl}/training-session/${sessionId}/animation`, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/training-session/${sessionId}/animation`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getTrainingSessionsOfUser: async (
    idToken: string,
    userId: string,
  ): Promise<TrainingSession[]> => {
    try {
      const response = await axios.get<TrainingSession[]>(
        `${baseUrl}/training-session?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/training-session?userId=${userId}`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  // submitSurveyAnswers: async (
  //   submitData: SurveyData,
  // ): Promise<AxiosResponse> => {
  //   try {
  //     const response = await axios.post(`${baseUrl}/feedback`, submitData);
  //     return response;
  //   } catch (e) {
  //     Sentry.setContext('request', {
  //       method: 'post',
  //       url: `${baseUrl}/feedback`,
  //     });
  //     Sentry.captureException(e);

  //     throw e;
  //   }
  // },
  postCapture: async (data: CaptureData): Promise<AxiosResponse> => {
    try {
      const response = await axios.post(`${baseUrl}/image`, data);
      return response;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/image`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postTrackingData: async (
    trackingData: TrackingData,
  ): Promise<AxiosResponse> => {
    try {
      const response = await axios.post(`${baseUrl}/tracking`, trackingData);
      return response;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/tracking`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postConnect: async (authState: string): Promise<Connect> => {
    try {
      const response = await axios.post<Connect>(`${baseUrl}/connect`, {
        authState,
      });
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/connect`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postUserPreferences: async (
    idToken: string,
    userPreferences: UserPreferences,
  ): Promise<User> => {
    try {
      const response = await axios.post<User>(
        `${baseUrl}/user/preferences`,
        userPreferences,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/user/preferences`,
        preferences: userPreferences,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postUserNotificationsToken: async (
    idToken: string,
    token: string,
    platform: 'ios' | 'android' | 'web',
  ): Promise<{ message: string }> => {
    try {
      const response = await axios.post<{ message: string }>(
        `${baseUrl}/user/notifications-token`,
        { token, platform },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/user/notifications-token`,
        preferences: { token, platform },
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postUserAttributes: async (
    idToken: string,
    userAttributes: Partial<UserAttributes>,
  ): Promise<User> => {
    try {
      const response = await axios.post<User>(
        `${baseUrl}/user/attributes`,
        userAttributes,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/user/attributes`,
        preferences: userAttributes,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getCourseSuggestion: async (
    idToken: string,
  ): Promise<CourseSuggestionResponse> => {
    try {
      const response = await axios.get<CourseSuggestionResponse>(
        `${baseUrl}/course-suggestion`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/course-suggestion`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getConnect: async (id: string): Promise<GetConnectResponseBody> => {
    try {
      const response = await axios.get<GetConnectResponseBody>(
        `${baseUrl}/connect/${id}`,
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/connect/${id}`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  putConnect: async (
    id: string,
    actions: PutConnectIdAction[],
  ): Promise<Connect> => {
    try {
      const response = await axios.put<Connect>(`${baseUrl}/connect/${id}`, {
        actions,
      });
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'put',
        url: `${baseUrl}/connect/${id}`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getHome: async (
    idToken: string,
    includePreviewCourses = false,
  ): Promise<HomeContents> => {
    try {
      const response = await axios.get<HomeContents>(
        `${baseUrl}/home?includePreviewCourses=${
          includePreviewCourses ? 1 : 0
        }`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/home`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getPopularity: async (): Promise<Popularity> => {
    try {
      const response = await axios.get<Popularity>(
        `${baseUrl}/stats/popularity`,
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/stats/popularity`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postSaveForLater: async (
    idToken: string,
    itemType: 'course' | 'videoSession',
    itemId: string,
  ): Promise<User> => {
    try {
      const response = await axios.post<User>(
        `${baseUrl}/user/save-for-later`,
        {
          id: itemId,
          type: itemType,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/user/save-for-later`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postSubscribeTo: async (idToken: string, topic: string): Promise<User> => {
    try {
      const response = await axios.post<User>(
        `${baseUrl}/user/subscribe-to`,
        {
          topic,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/user/subscribe-to`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  getUserUpdatedPlan: async (idToken: string): Promise<User> => {
    try {
      const response = await axios.get<User>(`${baseUrl}/user/updated-plan`, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/user/updated-plan`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },
  getUserMe: async (idToken: string): Promise<User> => {
    try {
      const response = await axios.get<User>(`${baseUrl}/user/me`, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      console.log({ response });
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/user/me`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },
  getUserPoses: async (idToken: string): Promise<UserPose[]> => {
    try {
      const response = await axios.get<{ poses: UserPose[] }>(
        `${baseUrl}/user/poses`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data.poses;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'get',
        url: `${baseUrl}/user/poses`,
      });
      Sentry.captureException(e);
      throw e;
    }
  },
  deleteTrainingSessionImage: async (
    idToken: string,
    trainingSessionId: string,
    image: string,
  ): Promise<TrainingSession> => {
    try {
      const response = await axios.request<TrainingSession>({
        method: 'delete',
        url: `${baseUrl}/image`,
        data: JSON.stringify({
          trainingSessionId,
          image,
        }),
        headers: { Authorization: `Bearer ${idToken}` },
      });
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'delete',
        url: `${baseUrl}/image`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postValidateTransaction: async (
    idToken: string,
    receipt: string,
    productId: string,
  ): Promise<ValidateTransaction> => {
    try {
      const response = await axios.post<ValidateTransaction>(
        `${baseUrl}/validate-transaction`,
        {
          receipt,
          productId,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/validate-transaction`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postUserRestorePurchases: async (
    idToken: string,
    // The type has been defined on React Native. We'll just take `any` here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    purchases: any[],
  ): Promise<{ user: User; valid: boolean }> => {
    try {
      const response = await axios.post<{ user: User; valid: boolean }>(
        `${baseUrl}/user/restore-purchases`,
        purchases,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/user/restore-purchases`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
  postCreateSubscriptionOffer: async ({
    idToken,
    payload,
  }: {
    idToken: string;
    payload: CreateSubscriptionOfferRequestBody;
  }): Promise<CreateSubscriptionOfferResponseBody> => {
    try {
      const response = await axios.post<CreateSubscriptionOfferResponseBody>(
        `${baseUrl}/subscription-offer`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      return response.data;
    } catch (e) {
      Sentry.setContext('request', {
        method: 'post',
        url: `${baseUrl}/user/restore-purchases`,
      });
      Sentry.captureException(e);

      throw e;
    }
  },
});

export default API;
