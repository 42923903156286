import type { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { APIResponseError, useYougaClientApi } from '@youga/youga-client-api';
import type { PoseLang } from '@youga/youga-interfaces';

export interface UsePosesLangResult
  extends SWRResponse<PoseLang[], APIResponseError> {}

export function usePosesLang(): UsePosesLangResult {
  const { publicFetcher } = useYougaClientApi();
  const { data, isValidating, mutate } = useSWRImmutable<PoseLang[]>(
    `/home/posesLang`,
    {
      fetcher: publicFetcher,
    },
  );

  return {
    data,
    isValidating,
    mutate,
  };
}
