import * as React from 'react';
import useSWRImmutable from 'swr/immutable';

import type {
  GetCoursesResponseBody,
  Course,
  LabelName,
} from '@youga/youga-interfaces';
import { useYougaClientApi } from '../YougaClientApiProvider';
import { useUser } from '@youga/youga-client-api';

export function useCourses(language: string) {
  const { publicFetcher } = useYougaClientApi();
  const { data: user } = useUser();
  let response = useSWRImmutable<GetCoursesResponseBody>(`/courses`, {
    fetcher: publicFetcher,
  });

  const sortedCourses = React.useMemo(() => {
    const coursesList = response.data
      ? Object.keys(response.data?.courses)
          .map((courseId) => response.data?.courses[courseId])
          .filter(notEmpty)
          .map((data) => {
            return {
              ...data,
              title: language !== 'pl' ? data.titleEng : data.title,
              subtitle: language !== 'pl' ? data.subtitleEng : data.subtitle,
              description:
                language !== 'pl' ? data.descriptionEng : data.description,
            };
          })
      : null;

    return coursesList ? sortCourses(coursesList, user?.suggestedCourses) : [];
  }, [response.data, language]);

  type JSONObject = {
    [key: string]: Course;
  };

  function replaceDescription(
    jsonObj: Record<string, Course> | undefined,
    language: string,
  ): JSONObject {
    const result: JSONObject = {};
    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key) && language !== 'pl') {
        result[key] = {
          ...jsonObj[key],
          description: jsonObj[key].descriptionEng,
          title: jsonObj[key].titleEng,
          subtitle: jsonObj[key].subtitleEng,
        };
      } else {
        result[key] = {
          ...jsonObj[key],
        };
      }
    }

    return result;
  }

  return {
    ...sortedCourses,
    data: sortedCourses
      ? {
          ...response.data,
          courses: replaceDescription(response.data?.courses, language),
          sortedCourses,
          isValidating: false,
        }
      : null,
  };
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

function shuffle<T>(array: T[]) {
  for (let i = array.length - 1; 0 < i; --i) {
    const j = Math.floor(Math.random() * i);
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export const sortCourses = (
  courses: Course[],
  suggestedCourses: string[] = [],
): string[] => {
  const personalPriorities = suggestedCourses.reduce<{ [key: string]: number }>(
    (prios, courseId, index) => {
      // eslint-disable-next-line no-param-reassign
      prios[courseId] = suggestedCourses.length - index;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return prios;
    },
    {},
  );

  const coursesShuffled = [...courses];

  shuffle(coursesShuffled); // mutates the array

  const sortedCourseIds = coursesShuffled
    .sort((a, b) => {
      const isAPreview = a.preview ? 1 : 0;
      const isBPreview = b.preview ? 1 : 0;

      return Math.sign(isAPreview - isBPreview);
    })
    .sort((a, b) => {
      const coursePriorityA = personalPriorities[a.id] || 0;
      const coursePriorityB = personalPriorities[b.id] || 0;

      return coursePriorityB - coursePriorityA;
    })
    .map((course) => course.id);

  return sortedCourseIds;
};

export type FlagType = 'POPULAR' | 'NEW';

export const flags: Record<FlagType, LabelName> = {
  POPULAR: 'POPULAR',
  NEW: 'NEW',
};

export function useCoursesFavourites(language: string): Course[] {
  const { data: coursesData } = useCourses(language);
  const { data: user } = useUser();
  const allCourses = (coursesData?.sortedCourses ?? [])
    .map((courseId: string | number) => coursesData?.courses?.[courseId])
    .filter(notEmpty)
    .map((data) => {
      return {
        ...data,
        title: language !== 'pl' ? data.titleEng : data.title,
        subtitle: language !== 'pl' ? data.subtitleEng : data.subtitle,
        description: language !== 'pl' ? data.descriptionEng : data.description,
        id: data.id,
      };
    });

  const favorites = user?.preferences?.favoriteCourses || [];
  return allCourses
    .filter((course: { id: string }) => favorites.indexOf(course.id) !== -1)
    .sort(
      (a: { id: string }, b: { id: string }) =>
        favorites.indexOf(a.id) - favorites.indexOf(b.id),
    ) as Course[];
}

export function useCoursesAll(language: string): Course[] {
  const { data: coursesData } = useCourses(language);
  const allCourses = (coursesData?.sortedCourses ?? [])
    .map((courseId: string | number) => coursesData?.courses?.[courseId])
    .filter(notEmpty)
    .map((data) => {
      return {
        ...data,
        title: language === 'pl' ? data.title : data.titleEng,
        subtitle: language !== 'pl' ? data.subtitleEng : data.subtitle,
        description: language !== 'pl' ? data.descriptionEng : data.description,
      };
    });

  const labelsList: Course[] = allCourses.filter(
    (course) => course.labels?.length !== 0,
  );
  const noLabelsList: Course[] = allCourses.filter(
    (course) => course.labels?.length === 0,
  );

  labelsList.sort((course: Course) => {
    return course.labels?.includes(flags.NEW) ? -1 : 1;
  });

  return labelsList.concat(noLabelsList);
}

export type JSONObject = {
  [key: string]: Course;
};

export function replaceDescription(
  jsonObj: Record<string, Course> | undefined,
  language: string,
): JSONObject {
  const result: JSONObject = {};
  for (const key in jsonObj) {
    if (jsonObj.hasOwnProperty(key) && language !== 'pl') {
      result[key] = {
        ...jsonObj[key],
        description: jsonObj[key].descriptionEng,
        title: jsonObj[key].titleEng,
        subtitle: jsonObj[key].subtitleEng,
      };
    } else {
      result[key] = {
        ...jsonObj[key],
      };
    }
  }

  return result;
}
