import * as React from 'react';
import { SWRConfig, useSWRConfig } from 'swr';

import createApi from './API';
import { createApiFetcher } from './fetcher';

export interface YougaClientApiContext {
  userId?: string;
  token?: string;
  api: ReturnType<typeof createApi>;
  fetcher: ReturnType<typeof createApiFetcher>;
  /**
   * Use this to prevent bypassing cache
   * This fetcher will not use "Authorization" header
   */
  publicFetcher: ReturnType<typeof createApiFetcher>;
}

export const YougaClientApiContextImpl = React.createContext<
  YougaClientApiContext | undefined
>(undefined);

interface Props {
  baseUrl: string;
  sentry?: Parameters<typeof createApi>[1];
  userId?: string;
  token?: string;
}

export function YougaClientApiProvider({
  baseUrl,
  sentry,
  userId,
  token,
  children,
}: React.PropsWithChildren<Props>) {
  const api = React.useMemo(
    () => createApi(baseUrl, sentry),
    [baseUrl, sentry],
  );
  const fetcher = React.useMemo(
    () => createApiFetcher(baseUrl, token),
    [baseUrl, token],
  );
  const publicFetcher = React.useMemo(
    () => createApiFetcher(baseUrl),
    [baseUrl],
  );

  const { cache } = useSWRConfig();

  React.useEffect(() => {
    if (userId == null) {
      if (typeof (cache as any).clear === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cache.clear();
      }
    }
  }, [userId]);

  return (
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <YougaClientApiContextImpl.Provider
        value={{ api, fetcher, publicFetcher, userId, token }}
      >
        {children}
      </YougaClientApiContextImpl.Provider>
    </SWRConfig>
  );
}

export function useYougaClientApi() {
  const contextValue = React.useContext(YougaClientApiContextImpl);

  if (contextValue == null) {
    throw new Error(
      'useYougaClientApi() must be used within a YougaClientApiProvider',
    );
  }

  return contextValue;
}
