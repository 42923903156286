import type { SWRResponse } from 'swr';
import React from 'react';
import useSWR from 'swr/immutable';
import { APIResponseError, useYougaClientApi } from '@youga/youga-client-api';
import type {
  BusinessUser,
  BusinessCustomerUser,
  Voucher,
} from '@youga/youga-interfaces';
import API from '../API';

export interface useBusinessCustomersResult
  extends SWRResponse<BusinessUser[], APIResponseError> {
  createBusinessCustomer: (
    newUserPreferences: Omit<BusinessUser, 'id' | 'createdAt' | 'updatedAt'>,
  ) => Promise<void>;
  getBuisnessUsers: () => void;
  deleteBuisnessUser: (userId: string) => void;
}

export function useBusinessCustomers(): useBusinessCustomersResult {
  const { api, publicFetcher, token } = useYougaClientApi();
  const {
    data: swrData,
    isValidating,
    mutate,
  } = useSWR<BusinessUser[]>(`/buisness-user`, {
    fetcher: publicFetcher,
  });

  const createBusinessCustomer = React.useCallback(
    async (
      businessCustomerData: Omit<
        BusinessUser,
        'id' | 'createdAt' | 'updatedAt'
      >,
    ) => {
      if (token == null) {
        throw new Error(`Token is not defined.`);
      }
      try {
        await api.createBuisnessUser(token, businessCustomerData);
        mutate();
      } catch (error: unknown) {
        throw error;
      }
    },
    [token, mutate],
  );

  const getBuisnessUsers = React.useCallback(async () => {
    try {
      await mutate();
    } catch (error: unknown) {
      throw error;
    }
  }, [mutate]);

  const deleteBuisnessUser = React.useCallback(
    async (userId: string) => {
      if (token == null) {
        throw new Error(`Token is not defined.`);
      }
      try {
        await api.deleteBuisnessUser(token, userId); //to use cognito auth
        mutate(); // Re-fetch the list after a user is deleted
      } catch (error: unknown) {
        throw error;
      }
    },
    [token, mutate],
  );

  return {
    data: swrData,
    isValidating: isValidating,
    createBusinessCustomer,
    getBuisnessUsers,
    deleteBuisnessUser,
    mutate, // Include the mutate function in the return value
  };
}

export interface useBusinessCustomerResult
  extends SWRResponse<BusinessUser, APIResponseError> {
  generateUserCodes: (
    quantity: number,
    periodType: Voucher['periodType'],
  ) => Promise<Voucher[] | null>;
}

export function useBusinessCustomer(id: string): useBusinessCustomerResult {
  const { fetcher, api, token } = useYougaClientApi();

  // const { data, isValidating, mutate } = useSWR<BusinessUser>(
  //   `/buisnessCustomers?customerId=${id}`,
  //   {
  //     fetcher: fetcher,
  //   },
  // );

  const generateUserCodes = async (
    quantity: number,
    periodType: Voucher['periodType'],
  ) => {
    if (token && typeof periodType !== 'undefined') {
      // return await api.createB2bCodes(token, quantity, periodType);
      return new Promise((resolve) => {
        resolve([
          {
            id: 'xxx1',
            fingerprints: '4297F44B13955235245B2497399D7A93',
          },
          {
            id: 'xxx2',
            fingerprints: '4297F44B13955235245B2497399D7A93',
          },
        ]);
      });
    }
    return null;
  };

  return {
    data: {
      id: 'xadsfasdfadsf',
      email: 'email',
      productId: 'xxx',
      periodType: 'normal',
      expirationDate: '02-02-2023',
      purchaseDate: '02-02-2023',
      cancellationDate: '02-02-2023',
      createdAt: '02-02-2023',
      updatedAt: '02-02-2023',
      // @ts-ignore
      year: '2023',
      companyName: 'Stretchme',
      krs: 'ffafxs',
    },
    // mutate,
    isValidating: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    generateUserCodes,
  };
}

export interface useBusinessCustomerUsersResult
  extends SWRResponse<BusinessCustomerUser[], APIResponseError> {}

export function useBusinessCustomerUsers(
  id: string,
): useBusinessCustomerUsersResult {
  const { fetcher, api, token } = useYougaClientApi();
  // const { data, isValidating, mutate } = useSWR<BusinessUser[]>(
  //   `/buisnessCustomers?customerId=${id}`,
  //   {
  //     fetcher: fetcher,
  //   },
  // );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    data: [
      {
        id: 'x',
        email: 'michal.kanarek@gmail.com',
        activated: true,
      },
      {
        id: 'xf',
        email: 'michal.kanarek2@gmail.com',
        activated: false,
      },
    ],
    isValidating: false,
  };
}
