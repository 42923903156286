import * as React from 'react';
import useSWR from 'swr';

import { Connect } from '@youga/youga-interfaces';

import { useYougaClientApi } from '@youga/youga-client-api';

interface AdditionalOptions {
  refreshInterval: number;
  fallbackData?: Connect;
}

export function useConnect(
  connectId?: string,
  additionalOptions?: AdditionalOptions,
) {
  const result = useSWR<Connect>(
    () => (connectId != null ? `/connect/${connectId}` : null),
    additionalOptions,
  );

  return result;
}

export function useConnector() {
  const { fetcher, userId } = useYougaClientApi();

  const [error, setError] = React.useState<unknown>();
  const [initialConnectData, setInitialConnectData] = React.useState<Connect>();

  // 1. Create a new auth-connect object where the mobile app could store the credentials
  React.useEffect(() => {
    async function createInitialConnect() {
      try {
        const connect = await fetcher<Connect>(`/connect`, {
          method: 'POST',
          body: JSON.stringify({
            authState: userId ? 'signin' : 'signedout',
          }),
        });

        setInitialConnectData(connect);
        setError(undefined);
      } catch (error: unknown) {
        setError(error);
      }
    }

    createInitialConnect();
  }, [fetcher, userId]);

  // 2. Constantly fetch the auth-connect object to check for actions
  const { data: connectData } = useConnect(initialConnectData?.id, {
    refreshInterval: 2000,
    fallbackData: initialConnectData,
  });

  return {
    data: connectData,
    error,
  };
}
