import useSWRImmutable from 'swr/immutable';
import { useEffect, useState } from 'react';

import type { VideoSession } from '@youga/youga-interfaces';
import { useYougaClientApi } from '../YougaClientApiProvider';

export interface UseVideoSessionsResponseBody {
  instructors(
    instructors: any,
    language: string,
  ): { [key: string]: import('@youga/youga-interfaces').Instructor };
  videoSessions: Record<string, VideoSession>;
}

export function useVideoSessions(language: string) {
  const { publicFetcher } = useYougaClientApi();
  const response = useSWRImmutable<UseVideoSessionsResponseBody>(
    `/video-sessions`,
    {
      fetcher: publicFetcher,
    },
  );

  const [mutationArg, setMutationArg] = useState(language);

  useEffect(() => {
    setMutationArg(language);
  }, [language]);

  const [mutatedResponse, setMutatedResponse] =
    useState<UseVideoSessionsResponseBody | null>(null);

  useEffect(() => {
    if (response.data) {
      const mutateResponse = (
        data: UseVideoSessionsResponseBody,
        lang: string,
      ) => {
        const mutatedData: UseVideoSessionsResponseBody = {
          instructors: data.instructors,
          videoSessions: {},
        };

        Object.keys(data.videoSessions).forEach((key) => {
          const videoSession = data.videoSessions[key];
          const mutatedVideoSession: VideoSession = { ...videoSession };

          if (lang !== 'pl') {
            mutatedVideoSession.description =
              mutatedVideoSession.descriptionEng;
            mutatedVideoSession.title = mutatedVideoSession.titleEng;
            mutatedVideoSession.url = mutatedVideoSession.urlEng;
            mutatedVideoSession.urlHls = mutatedVideoSession.urlEngHls;
          }

          mutatedData.videoSessions[key] = mutatedVideoSession;
        });

        return mutatedData;
      };

      const newMutatedResponse = mutateResponse(response.data, language);
      setMutatedResponse(newMutatedResponse);
    }
  }, [response.data, language]);

  return {
    ...response,
    data: mutatedResponse,
  };
}
