import * as React from 'react';
import useSWRImmutable from 'swr/immutable';

import type {
  GetPosesResponseBody,
  PosesDataType,
} from '@youga/youga-interfaces';

import { useYougaClientApi } from '../YougaClientApiProvider';

export function usePoses() {
  const { publicFetcher } = useYougaClientApi();
  const response = useSWRImmutable<GetPosesResponseBody>(`/home/poses`, {
    fetcher: publicFetcher,
  });
  return response;
}

export function usePose(poseId?: string) {
  const { data } = usePoses();

  const pose = React.useMemo(() => {
    if (data == null) {
      return null;
    }

    const poseData = data.find((pose) => pose.id === poseId);

    if (!poseData || !poseData.de) {
      return null;
    }

    return poseData.de;
  }, [data, poseId]);

  return pose;
}

export function translatePose(poses: PosesDataType[], poseName: string) {
  const item = poses.find((pose) => pose.id === poseName);
  return item?.de ?? null;
}
