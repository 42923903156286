import { useTranslation } from 'react-i18next';

import { Box, Button, Image, Grid } from 'theme-ui';
import React from 'react';

import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';
import Header from '../../02_molecules/Header/Header';
import useAuth from '../../../hooks/useAuth';
import SubscriptionGateImage from '../../../assets/images/SubscriptionGate.png';

const SubscriptionGate: React.FC<unknown> = () => {
  const { t } = useTranslation();

  const { handleSignOut } = useAuth();

  return (
    <>
      <Header showButton={null} theme="light" />

      <Box
        sx={{
          position: 'relative',
          minHeight: 'calc(100vh - 3rem)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (orientation: portrait)': {
            minHeight: 'calc(100vh - 3.75rem)',
          },
          '@media (orientation: portrait) and (min-width: 48em)': {
            minHeight: 'calc(100vh - 5rem)',
          },
          '@media (orientation: landscape) and (min-height: 48em)': {
            minHeight: 'calc(100vh - 3.75rem)',
          },
          '@media (orientation: landscape) and (min-height: 62em)': {
            minHeight: 'calc(100vh - 5rem)',
          },
        }}
      >
        <Container>
          <Grid columns={[1, 1, 2]} gap="2.5rem">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: ['center', null, 'left'],
              }}
            >
              <Text variant="h1" sx={{ m: 0 }}>
                {t('DEIN_YUU_YOGA_ABO_IST_ABGELAUF')}
              </Text>
              <Text
                sx={{ fontSize: 0, mt: 5, lineHeight: 1.5, letterSpacing: 0 }}
              >
                {t('MOMENTAN_HAST_DU_KEINEN_ZUGRIF')}
              </Text>
              <Text
                sx={{ fontSize: 0, mt: 4, lineHeight: 1.5, letterSpacing: 0 }}
              >
                {t('HIER_KANNST_DU_EINFACH_UND_UMK')}
              </Text>
              <Box sx={{ mt: 5 }}>
                <Button
                  variant="whiteBorder"
                  onClick={(): void => {
                    handleSignOut();
                  }}
                >
                  {t('EINLOGGEN')}
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                mt: [6, null, 0],
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={SubscriptionGateImage}
                sx={{ maxHeight: '100vh', maxWidth: ['50%', null, '100%'] }}
              />
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SubscriptionGate;
