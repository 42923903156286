export class ResponseError<T extends unknown = unknown> extends Error {
  response: Response;
  body: T | undefined;
  constructor(response: Response, body?: T) {
    super(
      `Response failed with status ${response.status}: "${response.statusText}"`,
    );
    this.response = response;
    this.body = body;
    Object.setPrototypeOf(this, ResponseError.prototype);
  }
}

interface BodyErrorMessage {
  message: string;
}

function hasBodyErrorMessage(body: unknown): body is BodyErrorMessage {
  return typeof body === 'object' && body != null && 'message' in body;
}

export class ResponseUserNotExistsError extends ResponseError<BodyErrorMessage> {
  constructor(response: Response, body: BodyErrorMessage) {
    super(response, body);
    Object.setPrototypeOf(this, ResponseUserNotExistsError.prototype);
  }
}

export type APIResponseError = ResponseError | ResponseUserNotExistsError;

function createApiFetcher(
  baseUrl: string,
  authorizationToken?: string,
  additionalHeaders?: Record<string, unknown>,
) {
  return async <T>(path: string, init?: RequestInit): Promise<T> => {
    const method = init?.method ?? 'GET';
    console.log(`fetch (${method})`, path, init?.body);

    const now = new Date();
    try {
      await fetch(`${baseUrl}${path}`, {
        ...init,
        method,
        headers: {
          ...(authorizationToken != null
            ? { Authorization: `Bearer ${authorizationToken}` }
            : {}),
          ...additionalHeaders,
        },
      });
    } catch (e) {
      console.log({ e });
    }
    const response = await fetch(`${baseUrl}${path}`, {
      ...init,
      method,
      headers: {
        ...(authorizationToken != null
          ? { Authorization: `Bearer ${authorizationToken}` }
          : {}),
        ...additionalHeaders,
      },
    });

    const nowAfter = new Date();
    const diff = nowAfter.getTime() - now.getTime();
    const diffSeconds = diff / 1000;

    if (!response.ok) {
      console.log(
        `fetch response (${method}) [${diffSeconds}]`,
        path,
        response.status,
      );

      if (response.headers.get('content-type')?.includes('application/json')) {
        const body = await response.json();

        if (
          hasBodyErrorMessage(body) &&
          body.message === 'The user does not exist'
        ) {
          throw new ResponseUserNotExistsError(response, body);
        }

        throw new ResponseError(response, body);
      }

      throw new ResponseError(response);
    }

    const responseBody = await response.json();
    console.log(
      `fetch response (${method}) [${diffSeconds}]`,
      path,
      response.status,
      // responseBody,
    );
    return responseBody;
  };
}

export { createApiFetcher };
