import * as React from 'react';
import type { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

import type {
  User,
  UserAttributes,
  UserPreferences,
} from '@youga/youga-interfaces';
import { APIResponseError, ResponseError } from '@youga/youga-client-api';

import { useYougaClientApi } from '../YougaClientApiProvider';

export interface UseUserResult extends SWRResponse<User, APIResponseError> {
  refreshUser: () => Promise<void>;
  updateAttributes: (
    newUserAttributes: Partial<UserAttributes>,
  ) => Promise<void>;
  selfDelete: () => Promise<void>;
  updatePreferences: (newUserPreferences: UserPreferences) => Promise<void>;
  notificationsAllowed: boolean | undefined;
}

export function useUser(): UseUserResult {
  const { api, userId, token } = useYougaClientApi();

  const { data, isValidating, mutate, error } = useSWRImmutable<User>(
    userId != null ? `/user/me` : null,
    {
      // permanent retrieval of the user for subscription validation purpose
      refreshInterval: 60 * 1000 * 60 /*=1h*/,
      // catch errors and retry as fast as possible
      onErrorRetry: (
        error: unknown,
        _key,
        _config,
        revalidate,
        { retryCount },
      ) => {
        if (!(error instanceof ResponseError)) {
          return;
        }

        if (error.response.status === 404) {
          return;
        }

        setTimeout(() => {
          revalidate({ retryCount });
        }, 2500);
      },
    },
  );

  const refreshUser = React.useCallback(async () => {
    if (isValidating) {
      return;
    }
    await mutate();
  }, [isValidating, mutate]);

  const updateAttributes = React.useCallback(
    async (newUserAttributes: Partial<UserAttributes>) => {
      if (token == null) {
        throw new Error(`Token is not defined.`);
      }
      const newUser = await api.postUserAttributes(token, newUserAttributes);
      await mutate(newUser, false);
    },
    [token, mutate],
  );

  const selfDelete = React.useCallback(async () => {
    if (token == null) {
      throw new Error(`Token is not defined.`);
    }
    await api.selfDelete(token);
  }, [token, mutate]);

  const updatePreferences = React.useCallback(
    async (newUserPreferences: UserPreferences) => {
      if (token == null) {
        throw new Error(`Token is not defined.`);
      }

      // Optimistic update
      await mutate((currentUser) => {
        if (currentUser == null) {
          return currentUser;
        }
        return {
          ...currentUser,
          preferences: { ...currentUser.preferences, ...newUserPreferences },
        };
      }, false);

      try {
        const newUser = await api.postUserPreferences(
          token,
          newUserPreferences,
        );
        await mutate(newUser, false);
      } catch (error: unknown) {
        // if fails, we need to reset the optimistic update changes
        // we just fetch the GET api again
        await mutate();
        throw error;
      }
    },
    [token, mutate],
  );

  return {
    data,
    isValidating,
    mutate,
    refreshUser,
    updateAttributes,
    selfDelete,
    updatePreferences,
    notificationsAllowed: data?.preferences?.['notificationsActivated'],
    error,
  };
}

export function isUserDoneWithOnboarding(user?: User): boolean | null {
  if (user == null) {
    return null;
  }

  const result =
    user.preferences?.finishedOnboarding === true ||
    user.preferences?.skipOnboarding === true ||
    user.preferences?.avoidOnboarding === true;

  return result;
}

export function hasSeenEmailConfirmationHint(user?: User): boolean | null {
  if (user == null) {
    return null;
  }

  return user?.attributes?.postAuthShown === true;
}

export function hasTermsOfUseAgreed(user?: User) {
  return user?.attributes?.['custom:terms_agreed'] === 'true';
}
