import { useUser } from '@youga/youga-client-api';

export interface UseFavorites {
  updateFavorites: () => Promise<void>;
}

export function useFavorites(courseId?: string): UseFavorites {
  const { data: user, updatePreferences } = useUser();
  const updateFavorites = async function () {
    try {
      if (courseId) {
        let favorites = user?.preferences?.favoriteCourses || [];

        if (favorites.indexOf(courseId) !== -1) {
          favorites = favorites.filter((item) => item !== courseId);
        } else {
          favorites = [courseId].concat(favorites);
        }

        await updatePreferences({
          ...user?.preferences,
          favoriteCourses: favorites,
        });
      }
    } catch (error: unknown) {}
  };

  return {
    updateFavorites,
  };
}
