import type {
  HomeContents,
  Instructor,
  VideoSession,
} from '@youga/youga-interfaces';

import { useInstructors } from './useInstructors';
import { useVideoSessions } from './useVideoSessions';
import { useCourses } from './useCourses';

export function useHome(language: string) {
  const courses = useCourses(language);
  const instructors = useInstructors();
  const videoSessions = useVideoSessions(language);
  type JSONObject = {
    [key: string]: VideoSession;
  };

  type JSONObjectInstructors = {
    [key: string]: Instructor;
  };

  function replaceDescription(
    jsonObj: Record<string, VideoSession> | undefined,
    language: string,
  ): JSONObject {
    const result: JSONObject = {};
    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key) && language !== 'pl') {
        result[key] = {
          ...jsonObj[key],
          description: jsonObj[key].descriptionEng,
          title: jsonObj[key].titleEng,
          subtitle: jsonObj[key].subtitleEng,
          url: jsonObj[key].urlEng,
          urlHls: jsonObj[key].urlEngHls,
        };
      } else {
        result[key] = {
          ...jsonObj[key],
        };
      }
    }

    return result;
  }

  function replaceDescriptionInstructors(
    jsonObj: Record<string, Instructor> | undefined,
    language: string,
  ): JSONObjectInstructors {
    const result: JSONObjectInstructors = {};
    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key) && language !== 'pl') {
        result[key] = {
          ...jsonObj[key],
          description: jsonObj[key].descriptionEng,
        };
      } else {
        result[key] = {
          ...jsonObj[key],
        };
      }
    }

    return result;
  }

  const data: HomeContents | null =
    courses.data && instructors.data && videoSessions.data
      ? {
          courses: courses.data?.courses,
          sortedCourses: courses.data?.sortedCourses,
          instructors: replaceDescriptionInstructors(
            instructors.data?.instructors,
            language,
          ),
          videoSessions: replaceDescription(
            videoSessions.data?.videoSessions,
            language,
          ),
        }
      : null;

  return {
    data,
    isValidating:
      // courses.isValidating ||
      instructors.isValidating || videoSessions.isValidating,
  };
}

export function usePrefetchHome(language: string): void {
  useHome(language);
}
