import useSWR from 'swr';

import type { GetUserPosesResponseBody } from '@youga/youga-interfaces';

import { useYougaClientApi } from '../YougaClientApiProvider';

export function useUserPoses() {
  const { userId, fetcher } = useYougaClientApi();

  const result = useSWR<GetUserPosesResponseBody>(
    userId ? `/user/${userId}/poses` : null,
    {
      fetcher: () => fetcher(`/user/poses`) as any,
      dedupingInterval: 30000,
    },
  );

  return result;
}
