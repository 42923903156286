import React, { SyntheticEvent, useRef, useState } from 'react';

import { Link } from 'gatsby';
import { Image } from '@theme-ui/components';
import { useTranslation } from 'react-i18next';

import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import Checkbox from '../../01_atoms/Checkbox/Checkbox';
import IconApple from '../../../assets/icons/iconApple.svg';
import IconFb from '../../../assets/icons/iconFb.svg';
import IconGoogle from '../../../assets/icons/iconGoogle.svg';
import PasswordInput from '../../01_atoms/PasswordInput/PasswordInput';
import Text from '../../01_atoms/Text/Text';
import TextInput from '../../01_atoms/TextInput/TextInput';
import i18n from '../../../i18n/i18n';
import Spinner from '../../01_atoms/Spinner/Spinner';
import Select from '../../01_atoms/Select/Select';
import { Box } from 'theme-ui';

type SignUpFormProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  onClickFacebookLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickGoogleLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickAppleLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  setEmail: (v: string) => void;
  setPassword: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  conditionsAccepted: boolean;
  setConditionsAccepted: (v: boolean) => void;
  newsletter: string;
  clientCheck: (client: string) => Promise<boolean>;
  setNewsletter: (v: string) => void;
  setCode: (v: string) => void;
  confirmCode?: string;
};

const Style = {
  socialMediaButton: {
    cursor: 'pointer',
    marginTop: '0.5rem',
    backgroundColor: 'rgb(243, 243, 243)',
    flexDirection: 'row' as const,
    display: 'flex',
    alignItems: 'center',
  },
  buttonIcon: {},
  buttonText: {
    fontWeight: 'bold',
    marginLeft: 2,
  },
  loginButton: {
    cursor: 'pointer',
    marginTop: '3rem',
    // width: '12rem',
    display: 'block',
    alignSelf: 'flex-end',
  },
};

function debounce<T extends any[]>(fn: (...args: T) => void, delay: number) {
  let timeout: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
}

export default function SignUpForm({
  errorInformation,
  // onClickFacebookLogin,
  onClickGoogleLogin,
  onClickAppleLogin,
  clientCheck,
  setEmail,
  setPassword,
  setCurrentVariant,
  conditionsAccepted,
  setConditionsAccepted,
  newsletter,
  setNewsletter,
  setCode,
  confirmCode,
}: SignUpFormProps) {
  const { t } = useTranslation();

  const [hasCode, setHasCode] = useState(false);

  const setClientDebounced = useRef(
    debounce(async (value: string) => {
      console.log('dasd');
      await clientCheck(value);
    }, 600),
  ).current;

  return (
    <React.Fragment key="signup-submit-fragment">
      <Button
        disabled={!conditionsAccepted}
        onClick={onClickGoogleLogin}
        style={Style.socialMediaButton}
      >
        <Image style={Style.buttonIcon} src={IconGoogle} />
        <Text sx={Style.buttonText}>{t('LOGIN_GOOGLE')}</Text>
      </Button>
      {/* 
      <Button
        disabled={!conditionsAccepted}
        onClick={onClickFacebookLogin}
        style={Style.socialMediaButton}
      >
        <Image style={Style.buttonIcon} src={IconFb} />
        <Text sx={Style.buttonText}>{t('LOGIN_FACEBOOK')}</Text>
      </Button>
      */}
      <Button
        disabled={!conditionsAccepted}
        onClick={onClickAppleLogin}
        style={Style.socialMediaButton}
      >
        <Image style={Style.buttonIcon} src={IconApple} />
        <Text sx={Style.buttonText}>{t('LOGIN_APPLE')}</Text>
      </Button>

      <TextInput
        id="email"
        type="text"
        name="email"
        placeholder={t('EMAIL_ADDRESS')}
        required
        onChange={(e): void => {
          setEmail(e.target.value);
        }}
        error={
          errorInformation?.position === 'email' && errorInformation.element
        }
      />
      <Checkbox
        id="newsletter"
        name="newsletter"
        checked={hasCode}
        onChange={(e): void => {
          setHasCode(!hasCode);
        }}
      >
        {t('i_have_voucher')}
      </Checkbox>
      {hasCode && (
        <TextInput
          id="code"
          name="code"
          onChange={(e) => setClientDebounced(e.target.value)}
          placeholder={'Kod weryfikacyjny'}
        />
      )}
      <PasswordInput
        id="password"
        name="password"
        onChange={setPassword}
        placeholder={t('PASSWORD')}
        showValidation={true}
        required
        error={
          errorInformation?.position === 'password' && errorInformation.element
        }
      />
      <Checkbox
        id="conditions"
        name="conditions"
        checked={conditionsAccepted}
        onChange={(e): void => {
          setConditionsAccepted(e.target.checked);
        }}
      >
        {t('CONSENT')}{' '}
        <a
          target="_blank"
          href={
            i18n.language === 'pl'
              ? '/pdfs/terms-and-conditions.pdf'
              : '/pdfs/terms-and-conditions_eng.pdf'
          }
        >
          {t('TERMS_OF_USE')}
        </a>{' '}
        {t('ACCEPT')}{' '}
        <a
          target="_blank"
          href={
            i18n.language === 'pl'
              ? '/pdfs/privacy-policy.pdf'
              : '/pdfs/privacy-policy.pdf'
          }
        >
          {t('PRIVACY_POLICY')}
        </a>{' '}
        {t('PRIVACY_POLICY_2')}
      </Checkbox>
      <Checkbox
        id="newsletter"
        name="newsletter"
        checked={newsletter === 'true'}
        onChange={(e): void => {
          setNewsletter(e.target.checked ? 'true' : 'false');
        }}
      >
        {t('MARKETING_CONSENT')}
      </Checkbox>

      <Button
        type="submit"
        style={{ cursor: 'pointer', display: 'block', marginTop: '3rem' }}
        disabled={!conditionsAccepted}
      >
        {t('FREE_REGISTER_BUTTON')}
      </Button>
      {errorInformation?.position === 'default' && errorInformation.element}
      {errorInformation?.position === 'default' && errorInformation.element}
      <Button
        type="button"
        variant="text"
        style={{ marginTop: '2rem' }}
        onClick={(): void => setCurrentVariant('signin')}
      >
        {t('ALREADY_HAVE_AN_ACCOUNT')}
      </Button>
    </React.Fragment>
  );
}
