import React from 'react';
import { Router } from '@reach/router';

import TrainingSessionPage from '../TrainingSessionSection/TrainingSessionPage';
import PoseComparisonSection from '../PoseComparisonSection/PoseComparisonSection';
import PoseComparisonOverviewSection from '../PoseComparisonOverviewSection/PoseComparisonOverviewSection';
import HomeSection from '../HomeSection/HomeSection';
import CourseOverviewSection from '../CourseOverviewSection/CourseOverviewSection';
import CourseDetailSection from '../CourseDetailSection/CourseDetailSection';
import InstructorDetailSection from '../InstructorDetailSection/InstructorDetailSection';
import ExplainerSection from '../ExplainerSection/ExplainerSection';
import MyAccountSection from '../MyAccountSection/MyAccountSection';
import FinishSessionSection from '../FinishSessionSection/FinishSessionSection';
import MobileGate from '../MobileGate/MobileGate';
import LoadingGate from '../LoadingGate/LoadingGate';
import SubscriptionGate from '../SubscriptionGate/SubscriptionGate';
import useGateInformation, {
  GATE_INFORMATION,
} from '../../../hooks/useGateInformation';
import useServiceWorkerMessages from '../../../hooks/useServiceWorkerMessages';
import useSubscribePushManager from '../../../hooks/useSubscribePushManager';
import JourneyProgressPage from '../../03_organisms/JourneyProgress/JourneyProgressPage';
import MeinBereichSection from '../MeinBereichSection/MeinBereichSection';
import AccountGate from '../AccountGate/AccountGate';
import CookieManager from '../../02_molecules/CookieManager/CookieManager';

const AppSection: React.FC = () => {
  const gate = useGateInformation();

  useSubscribePushManager();
  useServiceWorkerMessages();
  const isProduction =
    process.env.GATSBY_BASE_URL !== 'https://app-dev.stretchme.pl';
  switch (gate) {
    case GATE_INFORMATION.MOBILE:
      return <MobileGate />;

    case GATE_INFORMATION.ACCOUNT_INVALID:
      return <AccountGate />;

    case GATE_INFORMATION.LICENSE:
      return <SubscriptionGate />;

    case GATE_INFORMATION.LOADING:
      return <LoadingGate />;

    case GATE_INFORMATION.PASS:
    default:
      return (
        <Router basepath="/app">
          <HomeSection path="/" />
          <ExplainerSection path="/how-it-works" />
          <MyAccountSection path="/my-account" />
          <CookieManager path="/my-account/cookie-manager" />
          <MeinBereichSection path="/mein-bereich" />
          <TrainingSessionPage path="/session/:videoId" />
          <FinishSessionSection path="/finish-session/:trainingSessionId/:rawFeedback" />
          <PoseComparisonSection path="/pose-comparison/:sessionId" />
          <PoseComparisonOverviewSection path="/pose-comparison" />
          <CourseOverviewSection path="/courses" />
          <CourseDetailSection
            path="/course-detail/:courseId"
            key={'asdasdasda'}
          />
          <CourseDetailSection
            path="/course-detail/:courseId/:startIntro"
            key={'asdasdasasadsada'}
          />
          <InstructorDetailSection path="/instructor-detail/:instructorId" />
          <JourneyProgressPage path="/journey/:courseId" />
        </Router>
      );
  }
};

export default AppSection;
