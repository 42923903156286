import useSWRImmutable from 'swr/immutable';

import type { Instructor } from '@youga/youga-interfaces';
import { useYougaClientApi } from '../YougaClientApiProvider';

export interface UseInstructorsResponseBody {
  instructors: Record<string, Instructor>;
}

export function useInstructors() {
  const { publicFetcher } = useYougaClientApi();
  const response = useSWRImmutable<UseInstructorsResponseBody>(`/instructors`, {
    fetcher: publicFetcher,
  });
  return response;
}
